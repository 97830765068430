<template>
  <div>
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="route">
          {{ label }}
        </router-link>
      </v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'

export default {
  name: 'BreadCrumbs',

  components: {
    AppBarNavIcon
  },

  props: {
    label: {
      type: String,
      required: true
    },

    route: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      arrowLeft: mdiChevronLeft
    }
  }
}
</script>
