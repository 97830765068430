<template>
  <div class="py-8">
    <BreadCrumbs :route="{ name: 'reports' }" label="Reports" />

    <v-row>
      <div class="ma-4 col-xs-6 col-md-8 report-details" v-if="report">
        <v-card>
          <v-card-title> Report details </v-card-title>

          <v-divider />

          <v-card-text>
            <v-row>
              <v-col cols="2" class="text-right">
                <label>Reported</label></v-col
              >
              <v-col cols="6">
                <router-link :to="reportedRoute">
                  <strong>{{ reportedLabel }}</strong>
                </router-link>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2" class="text-right">Reason</v-col>
              <v-col cols="6">{{ report.reason.label }}</v-col>
            </v-row>

            <v-row>
              <v-col cols="2" class="text-right pt-1">Description</v-col>
              <v-col cols="10" class="pt-0">
                <v-textarea
                  no-resize
                  dense
                  rows="6"
                  :value="report.description"
                  disabled
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2" class="text-right">Reported By</v-col>
              <v-col cols="6">
                <router-link
                  :to="{
                    name: 'user.details',
                    params: { id: this.reporter.id }
                  }"
                >
                  <UserAvatar
                    :avatar="reporter.avatar"
                    size="35"
                    class="mr-1 mt-0"
                  />
                  <strong>{{ this.reporter.full_name }}</strong>
                </router-link>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2" class="text-right">Date Reported</v-col>
              <v-col cols="6">
                {{ report.addedDate }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2" class="text-right">Attachments</v-col>
              <v-col cols="10">
                <Attachments :files="report.attachments"></Attachments>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-row>

    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UserAvatar from '@/components/UserAvatar'
import BreadCrumbs from '@/layouts/shared/BreadCrumbs'
import Attachments from './components/Attachments'

export default {
  name: 'ReportDetails',

  components: {
    Attachments,
    BreadCrumbs,
    UserAvatar
  },

  data() {
    return {
      loading: false
    }
  },

  computed: {
    ...mapState({
      report: (state) => state.report.reportDetails
    }),

    reporter() {
      return this.report.reporter
    },

    reportType() {
      return this.report.report_type
    },

    reportedRoute() {
      let route = {
        name: '',
        params: { id: this.report.reportable.id }
      }

      switch (this.reportType) {
        case 'users':
        default:
          route.name = 'user.details'
      }

      return route
    },

    reportedLabel() {
      let reported = ''

      switch (this.reportType) {
        case 'users':
        default:
          reported = this.report.reportable.full_name ?? 'Reocrd'
      }

      return reported
    }
  },

  methods: {
    async reportDetails() {
      this.loading = true
      await this.$store.dispatch(
        'report/getReportDetails',
        this.$route.params.id
      )
      this.loading = false
    }
  },

  created() {
    this.reportDetails()
  },

  destroyed() {
    this.$store.commit('report/clearReportDetails')
  },

  watch: {
    $route() {
      this.reportDetails()
    }
  }
}
</script>

<style scoped>
.report-details {
  min-width: 450px;
  max-width: 900px;
}
</style>
