<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />

      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>
      <v-toolbar-title v-else class="headline font-weight-bold"
        >Reports ({{ listMeta.total }})</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-row v-show="showSearch" align="center" style="max-width: 300px">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search name"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <v-btn class="ml-5" icon @click="showSearchField">
        <img src="@/assets/icons/search.svg" alt height="17.7px" />
      </v-btn>
    </v-app-bar>

    <div
      class="mt-12 mx-4"
      id="user-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th width="20%" colspan="2" class="text-left pa-0">Reporter</th>
            <th width="20%">Reported</th>
            <th width="20%">Type</th>
            <th width="20%">Reason</th>
            <th width="20%">Date Reported</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="report in reports"
            :key="report.id"
            class="clickable"
            @click="
              $router.push({
                name: 'report.details',
                params: { id: report.id }
              })
            "
          >
            <td colspan="2">
              <user-avatar
                :avatar="report.reporter.avatar"
                size="40"
                class="mr-4"
              />
              {{ report.reporter.full_name }}
            </td>
            <td class="text-capitalize">{{ report.reportedRecord }}</td>
            <td class="text-capitalize">{{ report.report_type }}</td>
            <td class="text-capitalize">{{ report.reason.label }}</td>
            <td>{{ report.addedDate }}</td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td class="py-3 pl-0" width="5%">
                <v-skeleton-loader
                  class="avatar-loading--36"
                  type="avatar"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(reports.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel
} from '@mdi/js'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import UserAvatar from '@/components/UserAvatar'
import startCase from 'lodash/startCase'
import get from 'lodash/get'

export default {
  name: 'ReportsPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll
  },

  components: {
    AppBarNavIcon,
    UserAvatar
  },

  data() {
    return {
      loadMoreIndicator: false,
      loading: false,
      showSearch: false,
      filter: {
        search: ''
      },
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel
      }
    }
  },

  created() {
    this.clearReport()
    this.fetchReports(1)
  },

  computed: {
    ...mapState({
      reports: (state) => state.report.list,
      listMeta: (state) => state.report.listMeta
    }),

    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    }
  },

  methods: {
    get,
    ...mapActions({
      getReports: 'report/getReports'
    }),
    startCase,
    ...mapMutations({
      clearReport: 'report/clearReportsList'
    }),

    pageChanged(page) {
      this.fetchReports(page)
    },

    async loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        try {
          this.loadMoreIndicator = true
          await this.fetchReports(this.listMeta.current_page + 1)
        } finally {
          this.loadMoreIndicator = false
        }
      }
    },

    search: debounce(function () {
      this.clearReport()
      this.fetchReports()
    }, 600),

    async fetchReports(page = 1) {
      let params = { page }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true

      await this.getReports(params)

      this.loading = false
    },
    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    }
  }
}
</script>
<style scoped>
.load-more-indicator {
  max-width: 620px;
}
</style>
